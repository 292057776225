@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-size: 18px;
}

img {
  max-width: 100%;
  display: block;
}

ol.paper{
  padding: 1em 0 0 1em;
  margin: 0;
}

ol.paper li{
  margin-left: 1.4em;
  text-indent: -1.4em;
  list-style-type: none;
  list-style-position: inside;
  counter-increment: cnt;
}

ol.paper li::before {
  display: marker;
  content: "[" counter(cnt) "] ";
}
